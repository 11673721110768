import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserLoginResult } from '../shared/models/auth';
//
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, RedirectRequest } from '@azure/msal-browser';
import { map, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenUsuario: string;
  private nombreUsuario: string;

  constructor(
    protected _http: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {}

  /*   protected SetBasicHeader(): object {
      return {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
  } */

  // Header con Key MS
  protected SetBasicHeader(): object {
    let result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-functions-key': environment.MSKeyGeneral,
      }),
    };
    return result;
  }

  //Estado de sesion MS
  statusMSLogin() {
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        //  let _this = this;
        // Login Caranty
        this.userLogin(result.payload).subscribe({
          next: (data: UserLoginResult) => {
            console.log('data 1', data.user);
            localStorage.setItem('caranty_session', window.btoa(JSON.stringify(data.user)));
            if (data.status === 'success') {
              // Estatus Login
              this.loginState(data.user.token, data.user.username).subscribe({
                next: (data: any) => {
                  console.log('data 2', data);
                  this.router.navigate(['/prospectos']);
                },
                error: () => {
                  // this.SetMsjError('Login.Msj_error_InvalidResponse', 'Cod: 0-00');
                  console.log(' Estatus Login ');
                },
              });
              // Estatus Login
            }
          },
          error: () => {
            // this.SetMsjError('Login.Msj_error_InvalidResponse', 'Cod: 0-00');
          },
        });
        // Login Caranty
      });
  }

  // load Sesion Caranty
  public userLogin(payload: any): Observable<any> {
    console.log('ResponseAPI ', payload);
    return this._http
      .post(`${environment.WebApiUrlCRM}/api/login`, payload, this.SetBasicHeader())
      .pipe(map((response: any) => <UserLoginResult>response));
  }

  //Loa status Sesion Caranty
  public loginState(UserToken: string, userName: string): Observable<any> {
    console.log('ResponseAPI 2: ' + UserToken + ' -' + userName);
    const data = {
      token: UserToken,
      username: userName,
    };
    console.log('logeado: ', data);
    return this._http
      .post(`${environment.WebApiUrlCRM}/api/login/estalogeado`, JSON.stringify(data), this.SetBasicHeader())
      .pipe(map((response: any) => <any>response));
    //,catchError(error => this.handleError(error)));
  }

  //Validar estado de sesion
  public getState() {
    const sessionCaranty: string = localStorage.getItem('caranty_session');
    if (sessionCaranty) {
      let userDetailLogin = JSON.parse(window.atob(localStorage.getItem('caranty_session')));
      console.log('getState', userDetailLogin);

      // Estatus Login
      this.loginState(userDetailLogin.token, userDetailLogin.username).subscribe({
        next: (data: any) => {
          console.log('getState data 2', data);
        },
        error: () => {
          console.log(' Estatus Login ');
        },
      });
      // Estatus Login
      // return true;
    } else {
      this.statusMSLogin();
      // this.logOutMS();
      // return false;
    }
  }

  validarCuentasActivas() {
    //const accountLoad = this.authService.instance.getAllAccounts().length > 0;
    // if (accountLoad) { }
    if (this.authService.instance.getActiveAccount() != null) {
      console.log('sesion Activa');
      //return true;
    } else {
      console.log('No existe sesion Activa');
      this.showLoginMS();
      //return false;
    }
  }

  //Show loginMS
  showLoginMS() {
    this.authService.loginRedirect();
  }

  protected handleError(error: Response) {
    try {
      if (error) {
        switch (Number(error.status)) {
          case 400:
            //this.Logout(true);
            break;
          case 401:
            //this.Logout(true);
            break;
          case 500:
            //this.Logout(true);
            break;
        }
      }
    } catch {}

    return error.json() || 'Server Error';
  }

  /**
   * restore user session
   */
  private restoreSession() {
    try {
      let _data = sessionStorage.getItem('caranty_session');
      if (_data) {
        let dataSesion = JSON.parse(window.atob(_data));
        if (dataSesion && dataSesion.username != '' && dataSesion.Token != '') {
          // this._dataService.ChangeUserSession(t);
        } else {
          // this.login();
        }
      } else {
        //  this.login();
      }
    } catch {
      // this.login();
    }
  }

  updateSesion() {}

  getModules() {}

  public getToken(): string {
    const userDetailLogin = JSON.parse(localStorage.getItem('caranty_session'));
    return userDetailLogin[0].token;
  }

  // Actualizacion de Manejo de Sesion ----------------------------------------------------------

  // headers para consumo de APIs
  headerGetAPI(): object {
    this.leerToken();
    if (this.tokenUsuario == 'error') {
      // this.cerraSesion();
    } else {
      let result = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-functions-key': environment.MSKeyGeneral,
          Authorization: this.tokenUsuario,
        }),
      };
      return result;
    }
  }

  headerGetAPICarantyamdev(): object {
    this.leerToken();
    if (this.tokenUsuario == 'error') {
      this.cerraSesion();
    } else {
      let result = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'api-key': environment.API_KEY_MANAGMENT,
          'x-functions-key': environment.MSKeyGeneral,
          Authorization: this.tokenUsuario,
        }),
      };
      return result;
    }
  }

  headerGetAPICarantyamdevCRM(): object {
    this.leerToken();
    if (this.tokenUsuario == 'error') {
      this.cerraSesion();
    } else {
      let result = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'api-key': environment.API_KEY_MANAGMENT,
          'x-functions-key': environment.MSKeyCRM,
          Authorization: this.tokenUsuario,
        }),
      };
      return result;
    }
  }

  // Redireccionamiento para Login en MS
  loginMicrosoft() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  // Obtener Token de Login Caranty
  loginCaranty(payload: any): Observable<any> {
    // console.log('ResponseAPI ', payload);
    return this._http
      .post(`${environment.WebApiUrlCRM}/api/login`, payload, this.SetBasicHeader())
      .pipe(map((response: any) => <UserLoginResult>response));
  }

  // Valida el estado de sesion de MS y genera Token Login Caranty
  validarSesion() {
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        // console.log('data', result.payload);
        // Login Caranty
        this.loginCaranty(result.payload).subscribe({
          next: (data: UserLoginResult) => {
            this.guardarToken(data.user[0].token, data.user[0].username);
            if (data.status === 'success') {
              this.router.navigate(['/dashboard']);
            }
          },
          error: () => {
            // this.SetMsjError('Login.Msj_error_InvalidResponse', 'Cod: 0-00');
          },
        });
        // Login Caranty
      });
  }

  // Almacenar datos sesion en localStorage
  guardarToken(idToken: string, userName: string) {
    // console.log( idToken + userName );
    this.tokenUsuario = idToken;
    this.nombreUsuario = userName;
    localStorage.setItem('token', idToken);
    localStorage.setItem('userName', userName);
  }

  // Obtener datos sesion de localStorage
  leerToken() {
    let _this = this;
    if (localStorage.getItem('token')) {
      this.tokenUsuario = localStorage.getItem('token');
    } else {
      this.tokenUsuario = 'error';
      Swal.fire({
        width: 350,
        heightAuto: false,
        position: 'center',
        icon: 'info',
        title: 'Actualizar sesión',
        text: 'Cerrando sesión correctamente',
        showConfirmButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'Aceptar',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.cerraSesion();
        }
      });
    }
    return this.tokenUsuario;
  }

  // Cerrar sesion de Login Caranty
  logutCaranty(paramsBody: any): Observable<any> {
    return this._http
      .post(`${environment.WebApiUrlCRM}/api/logout`, paramsBody, this.SetBasicHeader())
      .pipe(map((response: any) => <any>response));
  }

  cerraSesion() {
    // Logout Caranty
    this.logutCaranty(this.tokenUsuario).subscribe({
      next: (data: UserLoginResult) => {
        console.log('sesion cerrada ', data);
        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        this.authService.logoutRedirect();
      },
      error: () => {
        // this.SetMsjError('Login.Msj_error_InvalidResponse', 'Cod: 0-00');
      },
    });
    // Logout Caranty
  }
}
